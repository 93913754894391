import React from 'react'
import sanofi from '../../assets/sanofi.png';
import burger from '../../assets/burger.svg';
import { Menu } from './components/Menu'
import { useSettings } from '../../context/Settings';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import { ReactComponent as DialogLogo } from '../../assets/dialog-logo.svg';

export const Navbar = () => {
    const {settings, saveSettings} = useSettings();
    if (!settings.showNav || !settings.isAuthorized) return null;
    const openMenu = () => saveSettings({ showMenu: true, staticPage: null })

    const heightClass = settings.staticPage !== null ? 'nav--high' : '';
    const a11yHidden = settings.showMenu && !settings.staticPage;
    const tabIndex = a11yHidden ? -1 : undefined;
    const isNavHidden = !settings.isAuthEnabled && settings.language !== null;

    return (
        <>
            {isNavHidden && <nav className={`nav ${heightClass}`} aria-hidden={a11yHidden} style={a11yHidden ? {'display': 'none'} : {}}>
                <button className="nav__menu-btn button" onClick={openMenu} tabIndex={tabIndex}>
                    <img src={burger} alt="menu icon" aria-label={'menu'}/>
                </button>
                {settings.showNavLogo &&
                    <Link to='/' className={`nav__logo ${settings.navLogoSlideIn ? 'nav__logo--slide' : ''}`}>
                        <DialogLogo aria-label="Dialog Logo"/>
                    </Link>}
                <Link to={{pathname: "https://www.sanofi.com"}} target='_blank' tabIndex={tabIndex}>
                    <img className="nav__sanofi" src={sanofi} alt="Sanofi Logo" aria-label='sanofi website'/>
                </Link>
            </nav>}
            <CSSTransition
                in={settings.showMenu && !settings.staticPage}
                classNames={'slide-in'}
                timeout={600}
                appear={true}
                mountOnEnter
                unmountOnExit
            >
                <Menu />
            </CSSTransition>
        </>
    )
}
