import React from "react";
import { Icon } from "../../Layout/Icon/Icon";

export interface QuitModalProps {
  onStayClick: () => void;
  onLeaveClick: (e: React.MouseEvent) => void;
  onClickOut: () => void;
  text: React.ReactElement;
  stayBtnText: string;
  leaveBtnText: string;
  buttonOverrideStyle:
    | {
        marginTop: string;
      }
    | {
        marginTop?: undefined;
      };
}

export const QuitModal: React.FunctionComponent<QuitModalProps> = ({
  onStayClick,
  onLeaveClick,
  onClickOut,
  text,
  stayBtnText,
  leaveBtnText,
  buttonOverrideStyle,
}) => (
  <div className="quit-modal-wrapper" onClick={onClickOut}>
    <div className="quit-modal">
      <Icon name="info" fill="#1E223C" size={28} />
      <p className="quit-modal__text">{text}</p>
      <div className="quit-modal__buttons-wrapper">
        <button
          className="quit-button quit-button--stay button"
          onClick={onStayClick}
        >
          <span style={buttonOverrideStyle}>{stayBtnText}</span>
        </button>
        <button
          className="quit-button quit-button--leave button"
          onClick={onLeaveClick}
        >
          <span style={buttonOverrideStyle}>{leaveBtnText}</span>
        </button>
      </div>
    </div>
  </div>
);
