import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import { Icon } from "../../Layout/Icon/Icon";
import { MicrophoneButton } from "./MicrophoneButton";
import { StartingQuestion } from "./StartingQuestion";
import { ActiveAnswer } from "./ActiveAnswer";
import { HistoryEntry, History } from "./History";
import { answerType } from "../types";
import { chooseResponseIndex, promptUserAllowMic } from "../roleplay.helpers";
import { analyticsEventInChatContext, analyticsEventInRoleplayContext, RoleplayEvent, setChatContextForAnalytics, userAnswerAnalytics } from "../../../analytics/analytics";
import { useRoleplayContext } from "../roleplay.helpers";
import { startSpeechReco, stopSpeechReco, useRecognizer } from "./chat.helpers";

export type extendedAnswerType = answerType & {id: string}
export interface ChatProps {
  setVideo: (videoId: string | null, videoUrl: string | null) => void;
  setInfoBoxClosed: React.Dispatch<React.SetStateAction<boolean>>;
  itShows: string;
  isWaiting: boolean;
  setWaiting: () => void;
  isMicEnabled: boolean;
  setMicEnabled: React.Dispatch<boolean>;
}

// Naming convention: user (doctor) asks questions. Patient answers.
export const Chat: React.FunctionComponent<ChatProps> = React.memo(({
  setInfoBoxClosed,
  setVideo,
  itShows,
  isWaiting,
  setWaiting,
  isMicEnabled,
  setMicEnabled,
}) => {
  const [activePatientsAnswer, setActivePatientsAnswer] = useState<extendedAnswerType | null>(null);
  const [chatHistory, setChatHistory] = useState<HistoryEntry[]>([]);
  const [isStartingQuestion, setIsStartingQuestion] = useState<boolean>(true);

  const { rolePlayData } = useRoleplayContext();

  const { answers,
    startingQuestion,
    startingAnswerId,
    questions,
    startsWithAnswer
  } = rolePlayData.roleplay

  const [speechError, setSpeechError] = useState<boolean>(false);
  const [isMicListening, setIsMicListening] = useState(false);
  const [isReplaying, setIsReplaying] = useState(false);
  const [showResultFromQuestion, setShowResultFromQuestion] = useState<undefined | "success" | "fail">(undefined);
  const inactiveTimer = useRef<null | ReturnType<typeof setTimeout>>(null)
  
  const isListening = isMicEnabled && isMicListening;
  const { t, i18n } = useTranslation();
  const { recognizer } = useRecognizer(i18n.language);
  const recognizedLongText = useRef<string>('')

  useEffect(() => {
    if (!recognizer) return;
    if (isWaiting && isListening) startSpeechReco(recognizer, checkAnswerFromRecognized);
    else stopSpeechReco(recognizer)
  }, [isWaiting, recognizer, isListening, isStartingQuestion])

  const checkAnswerFromRecognized = (recognizedText: string) => {
    if (!recognizedText) {
      recognizedLongText.current = ''
      return;
    };

    let responsesToChoose: string[] = [];
    if (isStartingQuestion) responsesToChoose = [startingQuestion];
    else if (activePatientsAnswer?.questions) {
      responsesToChoose = activePatientsAnswer.questions.map((el) => questions[el].text);
    }

    const similarityResult = chooseResponseIndex(
      recognizedLongText.current + ' ' + recognizedText,
      responsesToChoose
    );

    if (similarityResult === null) {
      setSpeechError(true);
      recognizedLongText.current = '';
    } else {
      if (similarityResult.isComplete) {
        if (isStartingQuestion) return onStartingQuestionClick(true);
        if (!activePatientsAnswer?.questions) return; // just for typescript;
        const mostSimilarQuestionID = activePatientsAnswer.questions[similarityResult.foundIndex];
        const nextPatientsAnswerID = questions[mostSimilarQuestionID].answerID;
        recognizedLongText.current = '';
        recognizer?.stopContinuousRecognitionAsync();
        onQuestionClick(nextPatientsAnswerID, mostSimilarQuestionID, true);
      } else {
        recognizedLongText.current += (' ' + recognizedText);
      }
    }
  };

  //Display error if user sentence not recognized as answer
  useEffect(() => {
    if (speechError === false || !isWaiting) return;
    const interval = setInterval(() => {
      setSpeechError(false);
    }, 3000);

    return () => clearInterval(interval);
  }, [speechError, isWaiting]);
  
  // Disable mic after 10 minutes of inactivity
  useEffect(() => {
    if (inactiveTimer.current) clearTimeout(inactiveTimer.current)
    if (isMicEnabled && isWaiting) {
      inactiveTimer.current = setTimeout(() => {
        setMicEnabled(false)
      }, 900000)
    }
  }, [setMicEnabled, activePatientsAnswer, isMicEnabled, isWaiting])
  

  const patientStarts = useCallback(() => {
    if (startsWithAnswer) {
      setActivePatientsAnswer({ ...answers[startingAnswerId], id: startingAnswerId });
      setIsStartingQuestion(false);
      setVideo(answers[startingAnswerId].videoID, answers[startingAnswerId].videoUrl);
    }
  }, [answers, startingAnswerId, startsWithAnswer, setVideo])

  useLayoutEffect(() => {
    if (startsWithAnswer) {
      patientStarts()
    }
  }, [patientStarts, startsWithAnswer]);

  setChatContextForAnalytics({
    patientAnswerID: activePatientsAnswer?.id,
    patientAnswerText: activePatientsAnswer?.text
  })

  const onQuestionClick = (nextAnswerID: string, clickedQuestionID: string, micSelected = false) => {
    stopSpeechReco(recognizer)
    const questionResult = questions[clickedQuestionID].result;
    if (questionResult) return setShowResultFromQuestion(questionResult)
    
    const nextAnswer = { ...answers[nextAnswerID], id: nextAnswerID };
    if (activePatientsAnswer) userAnswerAnalytics(activePatientsAnswer, nextAnswer, clickedQuestionID, micSelected, questions[clickedQuestionID].text)
    if (activePatientsAnswer) setChatHistory([...chatHistory, { ...activePatientsAnswer, clickedQuestionID }]);
    setIsReplaying(false);
    setActivePatientsAnswer(nextAnswer);
    setVideo(nextAnswer.videoID, nextAnswer.videoUrl);
    if (inactiveTimer.current) clearTimeout(inactiveTimer.current)
  };

  const onStartingQuestionClick = useCallback(
    (fromMic = false) => {
      stopSpeechReco(recognizer)
      const nextPatientAnswer = {...answers[startingAnswerId], id: startingAnswerId};
      userAnswerAnalytics(activePatientsAnswer, nextPatientAnswer, 'starting_answer', fromMic, startingQuestion)
      setActivePatientsAnswer(nextPatientAnswer);
      setIsStartingQuestion(false);
      setVideo(nextPatientAnswer.videoID, nextPatientAnswer.videoUrl);
      if (inactiveTimer.current) clearTimeout(inactiveTimer.current)
    },
    [activePatientsAnswer, answers, setVideo, startingAnswerId, startingQuestion, recognizer],
  )

  useEffect(() => {
    if (!isMicEnabled) return;
    if (isWaiting) setIsMicListening(true);
    else setIsMicListening(false);
  }, [isWaiting, isMicEnabled]);

  const restartRoleplay = () => {
    setIsStartingQuestion(true);
    setActivePatientsAnswer(null);
    setChatHistory([]);
    if (startsWithAnswer) {
      setShowResultFromQuestion(undefined)
      patientStarts();
    }
    analyticsEventInChatContext(RoleplayEvent.restart)
  };

  const toggleMic = async () => {
    if (!isWaiting) return;
    if (!isMicEnabled) await promptUserAllowMic()
    setInfoBoxClosed(true);
    setMicEnabled(!isMicEnabled);
    if (!isMicEnabled) analyticsEventInChatContext(RoleplayEvent.enable_mic)
    else analyticsEventInRoleplayContext(RoleplayEvent.disable_mic)
  }

  return (
    <div className={`content-dialog ${itShows}`}>
      <div className="dialog-wrapper">
        {!startsWithAnswer && <StartingQuestion
          startingQuestionInfo={t("roleplay.starting_question")}
          startingQuestionActive={isStartingQuestion}
          questionText={startingQuestion}
          onButtonClick={onStartingQuestionClick}
        />}
        {chatHistory.length > 0 && (
          <History
            chatHistory={chatHistory}
            activeRoleplayQuestions={questions}
          />
        )}
        {activePatientsAnswer && (
          <ActiveAnswer
            activePatientsAnswer={activePatientsAnswer}
            onQuestionClick={onQuestionClick}
            restartRoleplay={restartRoleplay}
            isWaiting={isWaiting}
            setVideo={setVideo}
            setWaiting={setWaiting}
            isMicEnabled={isMicEnabled}
            isReplaying={isReplaying}
            setIsReplaying={setIsReplaying}
            showResultFromQuestion={showResultFromQuestion}
          />
        )}
      </div>
      <CSSTransition
        in={speechError}
        classNames="fade"
        appear={true}
        timeout={{ enter: 800, exit: 500 }}
        mountOnEnter
        unmountOnExit
      >
        <div className="content-dialog__error">
          <Icon name="alarm" size={26} stroke="#1E223C" fill="#1E223C" />
          {t("roleplay.mic_error")}
        </div>
      </CSSTransition>
      {isWaiting && <MicrophoneButton
        isMicEnabled={isMicEnabled}
        isMicListening={isListening}
        toggleMic={toggleMic}
      />}
    </div>
  );
});