import React from 'react';
import { Link } from 'react-router-dom';
import sanofi from '../../assets/sanofi.png';

const SanofiLogo = () => {
  return (
    <Link to={{ pathname: "https://www.sanofi.com" }} className="sanofi-logo">
      <img src={sanofi} alt="Sanofi Logo" aria-label='sanofi website'/>
    </Link>
  )
}

export default SanofiLogo
