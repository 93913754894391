import React from "react";
import Icons from "../../../icons/icons.svg";

interface MyProps {
  name: string;
  stroke?: string;
  fill?: string;
  size?: number;
  height?: number;
  className?: string;
}

export const Icon: React.FunctionComponent<MyProps> = ({
  name,
  stroke,
  fill,
  size,
  height,
  className
}) => (
  <svg className={`icon icon-${name} ${className}`} stroke={stroke} fill={fill} width={size} height={height ? height : size} aria-hidden="true">
    <use xlinkHref={`${Icons}#icon-${name}`} />
  </svg>
);
