import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { Icon } from "../../Layout/Icon/Icon";

export interface MicInfoProps {
  isInfoClose: boolean;
  setIsInfoClose: () => void;
  setAllowMicrophone: () => void;
  buttonOverrideStyle:
    | {
        marginTop: string;
      }
    | {
        marginTop?: undefined;
      };
}

export const MicInfo: React.FunctionComponent<MicInfoProps> = ({
  isInfoClose,
  setIsInfoClose,
  setAllowMicrophone,
  buttonOverrideStyle,
}) => {
  const { t } = useTranslation();

  return (
    <CSSTransition
      in={!isInfoClose}
      classNames="fade"
      appear={true}
      timeout={{ enter: 800, exit: 500 }}
      mountOnEnter
      unmountOnExit
    >
      <div className="content-info">
        <button
          className="close-button button"
          onClick={setIsInfoClose}
          aria-label={t("icons.close")}
        >
          <Icon name="close" stroke="#1E223C" fill="none" size={60} />
        </button>
        <div className="content-info__wrapper">
          <Icon name="mic" fill="#1E223C" size={28} height={35} />
          <p className="content-info__text">
            <Trans>{t("roleplay.mic_info")}</Trans>
          </p>
          <button
            className="content-info__button button"
            onClick={setAllowMicrophone}
            aria-label="Allow microphone"
          >
            <span style={buttonOverrideStyle}>{t("roleplay.mic_btn")}</span>
          </button>
        </div>
      </div>
    </CSSTransition>
  );
};
