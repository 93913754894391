import { LanguageCodes } from "../../../i18n/i18n"

export const termsOfUseLink = (langCode: string): string => {
  switch (langCode) {
    case LanguageCodes.fi: return 'https://www.sanofi.com/en/legal-notice'
    case LanguageCodes.sv: return 'https://www.campus.sanofi/se/anvandarvillkor'
    case LanguageCodes.da: return 'https://www.sanofimed.dk/vilkar_betingelser'
    default: return '/'
  }
}

export const privacyPolicyLink = (langCode: string): string => {
  switch (langCode) {
    case LanguageCodes.fi: return 'https://www.sanofi.com/en/privacy-and-data-protection/healthcare-professionals'
    case LanguageCodes.sv: return 'https://www.sanofi.com/sv/sverige/data-privacy'
    case LanguageCodes.da: return 'https://www.sanofi.dk/da/data-privacy'
    default: return '/'
  }
}

export const hcpPortalLink = (langCode: string): string => {
  switch (langCode) {
    case LanguageCodes.fi: return 'https://www.sanofijasina.fi/'
    case LanguageCodes.sv: return 'https://www.sanofipro.se/'
    case LanguageCodes.da: return 'https://www.sanofimed.dk/'
    default: return '/'
  }
}
