import React from 'react'
import {ReactComponent as ReactLogo} from '../../assets/dialog-logo.svg';
import SanofiLogo from '../SanofiLogo/SanofiLogo';
import Dropdown from './Dropdown';

export const ChooseLanguage = () => {
  return (
    <div className="animation-overlay">
      <SanofiLogo/>
      <div className="login-center">
        <ReactLogo className="login-center__logo" width="" height=""/>
        <p className="login-center__text">Please select the country you have used to register on the Sanofi HCP portal.</p>
        <Dropdown />
      </div>
    </div>
  )
};
