import * as THREE from 'three';
import React, { Suspense, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { ResizeObserver } from '@juggle/resize-observer';
import { ANIMATION_CONFIG, mouseEvent, transformToBellCurve } from './waveAnimation.helpers';
import { audioData, plays } from '../Home/Home';

const vec = new THREE.Vector3();
const eqSquare = new THREE.Object3D();

export default React.memo(function AnimationCanvas() {
  return (
      <div className="canvas-wrapper">
        <Canvas
            resize={{ polyfill: ResizeObserver }}
            camera={{ position: [0, 2, 2.2], fov: 65 }}
            className={'animation-canvas'}
        >
          <Suspense fallback={null}>
            <Graph position={[0, 0, 0]} />
            <CameraMovements />
          </Suspense>
        </Canvas>
      </div>
  );
});

function Graph(props: any) {
  const ref = useRef<THREE.InstancedMesh | null>(null);
  const zNumber = ANIMATION_CONFIG.nLines;
  const zDelta = ANIMATION_CONFIG.spaceBetweenLines;

  const bars = 512;
  let arr: number[] = [];
  for (let i = 0; i < bars; i++) {
    arr.push(Math.random());
  }
  useFrame((state) => {
    if (!ref.current) return;

    // Generate new random numbers when there is no sound
    if (!plays) arr = arr.map(() => Math.random());

    for (let i = 0; i < bars * zNumber; i++) {
      const x = i % bars;
      const lineNumber = Math.floor(i / bars);
      const j = lineNumber * zDelta;

      const yMovement =
          ((Math.sin(x / 70 + state.clock.elapsedTime / 2) + 1) * 0.5 +
              (Math.sin(lineNumber / 10 + state.clock.elapsedTime) + 1) * 0.5 +
              (Math.sin(x / 70 + state.clock.elapsedTime / 3) + 0.5) * 0.2) *
          0.3 *
          transformToBellCurve(lineNumber, zNumber - 1) +
          (Math.sin(x / 60 + state.clock.elapsedTime) + 0.5) * 0.05;

      let y = plays
          ? yMovement +
          (audioData[x] / 255) * transformToBellCurve(lineNumber, zNumber - 1) * transformToBellCurve(x, bars - 1) * 2
          : yMovement;

      eqSquare.position.set((x - bars / 2) * 0.03, y, 0.8 - j);

      eqSquare.updateMatrix();
      ref.current.setMatrixAt(i, eqSquare.matrix);
    }
    ref.current.instanceMatrix.needsUpdate = true;
  });

  return (
      <instancedMesh ref={ref} args={[null, null, bars * zNumber]} {...props}>
        <planeGeometry args={[ANIMATION_CONFIG.particleWidth, ANIMATION_CONFIG.particleHeight]} />
        <meshBasicMaterial />
      </instancedMesh>
  );
}

function CameraMovements() {
  // Zoom in camera when user has pressed start
  return useFrame((state) => {
    state.camera.lookAt(0, 0, 0);

    const xSpeedModulator = plays ? 1 : 0.4;
    if (!mouseEvent.event) {
      state.camera.position.lerp(vec.set(0, 1, 3.2), 0.1);
      return;
    } else {
      state.camera.position.lerp(
          vec.set(
              ((window.innerWidth / 2 - mouseEvent.event.clientX) / window.innerWidth) * 2 * xSpeedModulator,
              (((window.innerHeight / 2 - mouseEvent.event.clientY) / window.innerHeight) * 4 + 1) * xSpeedModulator,
              3.2
          ),
          0.1
      );
    }
  });
}