import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { useSettings } from '../../context/Settings'
import { Authentication } from './Authentication';
import { useHistory } from "react-router";

const PrivateRoute: React.FunctionComponent<RouteProps> = (props) => {
  const { settings } = useSettings();
  const history = useHistory();

  if (!settings.isAuthorized) return <Authentication path={props.path as string | undefined}/>

  if (!settings.language) {
    history.push('/');
  }

  return (<Route {...props}></Route>)
}

export default PrivateRoute
