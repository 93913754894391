import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { Icon } from "../Layout/Icon/Icon";
import { Card } from "./components/Card";
import { Chat } from "./components/Chat";
import { QuitModal } from "./components/QuitModal";
import { TopBar } from "./components/TopBar";
import { MicInfo } from "./components/MicInfo";
import { useSettings } from "../../context/Settings";
import axios, { AxiosResponse } from "axios";
import { roleplayDataTypes } from "./types";
import { RoleplayEvent, setRoleplayContextForAnalytics, analyticsEventInRoleplayContext } from "../../analytics/analytics";
import { getAuthenticationToken } from "../../utils/authentication";
import { promptUserAllowMic, RoleplayContext } from "./roleplay.helpers";
import { VideosSwitch } from "./components/VideosSwitch";

export interface RoleplayProps {
  activeRoleplayID: string;
  setConversationActive: Dispatch<SetStateAction<boolean>>;
  onRoleplayFinish: () => void;
}

export const Roleplay: React.FunctionComponent<RoleplayProps> = ({
  activeRoleplayID,
  onRoleplayFinish
}) => {
  const [roleplayID, setRoleplayID] = useState(activeRoleplayID);
  const [videoId, setVideoId] = useState<string | null>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [data, setData] = useState<roleplayDataTypes | null>(null);
  const [isInfoClose, setIsInfoClose] = useState<boolean>(false);
  const [isCardOpen, setIsCardOpen] = useState<boolean>(false);
  const [showQuitConfirm, setShowQuitConfirm] = useState<boolean>(false);
  const [waitingVideoId, setWaitingVideoId] = useState<string | null>(null);
  const [waitingVideoUrl, setWaitingVideoUrl] = useState<string | null>(null);
  const [isWaiting, setIsWaiting] = useState(true);
  const [showAnswerVideo, setShowAnswerVideo] = useState<boolean>(false);
  const [isMicEnabled, setIsMicEnabled] = useState<boolean>(false);

  const { i18n, t } = useTranslation();
  const { settings, saveSettings } = useSettings();

  const buttonOverrideStyle = settings.isSafari ? { marginTop: "5px" } : {};

  useLayoutEffect(() => {
    // reset state between phase changes in multi phase module (fixes react player bug)
    if (data) {
      setData(null)
      setVideoId(null)
      setVideoUrl(null)
      setWaitingVideoId(null);
      setWaitingVideoUrl(null);
    }

    const fetchData = async () => {
      const authToken = getAuthenticationToken();
      const url = `/api/roleplay?id=${roleplayID}&language=${i18n.language}`;
      const { data }: AxiosResponse<roleplayDataTypes> = await axios(
        url,
        { headers: { 'Authorization': `Bearer ${authToken}`}}
      );
      setData(data);
      setWaitingVideoId(data.waitingVideoID);
      setWaitingVideoUrl(data.waitingVideoUrl);
      
      // this solves video autoplay issue on iOS when answer chosen with voice
      if (!data.roleplay.startsWithAnswer) {
        setVideoId(data.waitingVideoID)
        setVideoUrl(data.waitingVideoUrl)
      }
    };

    fetchData();
  }, [roleplayID, i18n.language]);

  const setWaiting = useCallback(
    () => {
      setShowAnswerVideo(false)
      setIsWaiting(true);
    },
    []
  )

  const setVideo = useCallback(
    (videoId: string | null, videoUrl: string | null) => {
      setIsWaiting(false);
      setVideoId(videoId);
      setVideoUrl(videoUrl);
    },
    [setIsWaiting, setVideoId, setVideoUrl]
  );

  useEffect(() => {
    saveSettings({ showNav: false });
    return () => {
      saveSettings({ showNav: true });
    };
  }, [saveSettings]);

  if (data === null) {
    return <></>;
  }

  setRoleplayContextForAnalytics({
    micAllowed: isMicEnabled,
    rolaplayLanguage: i18n.language,
    roleplayName: data.template_id
  })

  const onStay = () => {
    setShowQuitConfirm(false);
    analyticsEventInRoleplayContext(RoleplayEvent.stay);
  };

  const onLeave = (e: React.MouseEvent) => {
    setShowQuitConfirm(false);
    analyticsEventInRoleplayContext(RoleplayEvent.leave);
    onRoleplayFinish();
    e.stopPropagation();
  };

  const onAllowMic = async () => {
    await promptUserAllowMic();
    setIsInfoClose(true);
    setIsMicEnabled(true);
    analyticsEventInRoleplayContext(RoleplayEvent.allow_mic);
  };

  const onOpenCard = () => {
    if (!isCardOpen) analyticsEventInRoleplayContext(RoleplayEvent.patient_info);
    setIsCardOpen(!isCardOpen);
  };

  return (
    <RoleplayContext.Provider
      value={{ 
        rolePlayData: data, 
        setRoleplayID: setRoleplayID,
        setShowAnswerVideo,
        onRoleplayFinish,
      }}
      key={data.id}
    >
      <div className="roleplay">
        <VideosSwitch 
          setShowAnswerVideo={setShowAnswerVideo}
          videoId={videoId}
          videoUrl={videoUrl}
          showAnswerVideo={showAnswerVideo}
          videoPlayEnded={setWaiting}
          waitingVideoId={waitingVideoId}
          waitingVideoUrl={waitingVideoUrl}
        />

        <button
            className="person-button button"
            onClick={() => setShowQuitConfirm(true)}
            aria-label={t("icons.back")}
          >
          <Icon name="rectangle" stroke="#1E223C" fill="none" size={60} />
        </button>
        {showQuitConfirm && (
          <QuitModal
            onStayClick={onStay}
            onLeaveClick={onLeave}
            onClickOut={() => setShowQuitConfirm(false)}
            text={<Trans>{t("roleplay.quit_modal_text")}</Trans>}
            stayBtnText={t("roleplay.quit_modal_stay")}
            leaveBtnText={t("roleplay.quit_modal_leave")}
            buttonOverrideStyle={buttonOverrideStyle}
          />
        )}
        <div className="roleplay__content">
          {!isCardOpen && !data.roleplay.startsWithAnswer && isWaiting && (
            <MicInfo
              isInfoClose={isInfoClose}
              setIsInfoClose={() => setIsInfoClose(true)}
              setAllowMicrophone={onAllowMic}
              buttonOverrideStyle={buttonOverrideStyle}
            />
          )}
          <TopBar
            isCardOpen={isCardOpen}
            setIsCardOpen={onOpenCard}
            openCardText={t("icons.more_info")}
            closeCardText={t("icons.close")}
          />
          {isCardOpen && (
            <Card
              isCardOpen={isCardOpen}
              roleplayData={data}
            />
          )}
          <Chat
            setInfoBoxClosed={setIsInfoClose}
            isMicEnabled={isMicEnabled}
            setMicEnabled={setIsMicEnabled}
            setVideo={setVideo}
            itShows={isCardOpen ? "hide" : ""}
            isWaiting={isWaiting}
            setWaiting={setWaiting}
          />
        </div>
      </div>
    </RoleplayContext.Provider>
  );
};