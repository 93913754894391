import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSettings } from '../../../context/Settings';
import { Icon } from '../../Layout/Icon/Icon';
import { Subtitle } from '../Home'
import { SyncedTextSlide } from './SyncedTextSlide'

type SyncedTextProps = { 
  getTime: () => number | null;
  subtitles: Subtitle[]
}

export const SyncedText: React.FunctionComponent<SyncedTextProps> = ({ getTime, subtitles }) => {
  const [currentText, setCurrentText] = useState(subtitles[0].text);
  const { t } = useTranslation();
  const timeTable = useMemo(() => subtitles.map(el => el.startTime).reverse(), [subtitles])

  const { settings, saveSettings } = useSettings();
  const a11yHidden = settings.showMenu || !!settings.staticPage;
  const textIsLong = currentText.length > 50;
  const textSizeClass = textIsLong ? 'subtitles__text--small' : '';
  const buttonOverrideStyle = settings.isSafari ? {marginTop: "5px", marginLeft: "5px"} : {};

  useEffect(() => {
    const interval = setInterval(() => {
      const soundFileTime = getTime();
      if (soundFileTime) {
        const reversedIndex = timeTable.findIndex(subtitleTime => soundFileTime > subtitleTime)
        if (reversedIndex === -1) return;
        const index = subtitles.length - reversedIndex - 1;
        setCurrentText(subtitles[index].text)
      }
    }, 100);
    return () => clearInterval(interval);
  }, [getTime, subtitles, timeTable]);

  useEffect(() => {
    saveSettings({ navLogoSlideIn: true });
    return () => {
      saveSettings({ navLogoSlideIn: false });
    };
  }, [saveSettings]);

  return (
    <div className="animation-overlay" aria-hidden={a11yHidden}>
      <div className="subtitles">
        <div className={`subtitles__text ${textSizeClass}`}>
          <SyncedTextSlide currentText={currentText}/>
        </div>
      </div>
      <div className="narrated-menu" style={a11yHidden ? {'display': 'none'} : {}}>
        <Link className="narrated-menu__replay" to="/" aria-label={'go back'}     >
          <Icon name="replay" fill="none" stroke="black" size={18} ></Icon>
        </Link>
        <Link to="/roleplay" className="narrated-menu__skip">
          <Icon name="close" fill="none" stroke="black" size={30}></Icon>
          <span style={buttonOverrideStyle}>{t('narrated.skip')}</span>
        </Link>
      </div>
    </div>
  )
}
