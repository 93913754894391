import React from 'react'
import { SyncedWord } from './SyncedWord'

type SyncedTextSlideProps = { 
  currentText: string
}

export const SyncedTextSlide: React.FunctionComponent<SyncedTextSlideProps> = ({ currentText }) => {
  const words = currentText.split(' ');
  
  return (
    <>
      {words.map((word, index) => (<SyncedWord word={word} index={index} key={word + index + currentText.substr(0, 10)}/>))}
    </>
  )
}
