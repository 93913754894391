import React, { PropsWithChildren, useCallback, useState } from "react";
import { useContext } from "react";
import { LanguageCodes } from "../i18n/i18n";

export type StaticPageType = "about" | "references" | null;

type Settings = {
  showNav: boolean;
  showNavLogo: boolean;
  showMenu: boolean;
  userName?: string;
  staticPage: StaticPageType;
  isSafari: boolean;
  isAuthorized: boolean;
  navLogoSlideIn: boolean;
  language: LanguageCodes | null;
  isAuthEnabled: boolean
}

const isSafari = navigator.platform.indexOf('Mac') > -1 || /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const defaultSettings: Settings = {
  showNav: true,
  showNavLogo: true,
  showMenu: false,
  staticPage: null,
  isSafari,
  isAuthorized: process.env.REACT_APP_IS_AUTH_ENABLED === 'false',
  navLogoSlideIn: false,
  language: null,
  isAuthEnabled: process.env.REACT_APP_IS_AUTH_ENABLED === 'true'
};

const SettingsContext = React.createContext({
  settings: defaultSettings,
  saveSettings: (values: Partial<Settings>) => {},
});

export const SettingsProvider: React.FunctionComponent<PropsWithChildren<{settings: Settings}>> = ({ children, settings }) => {
  const [currentSettings, setCurrentSettings] = useState<Settings>(
      settings || defaultSettings
  );

  const saveSettings = useCallback(
      (values: Partial<Settings>) => setCurrentSettings(current => ({...current, ...values}))
      , []);

  return (
      <SettingsContext.Provider
          value={{ settings: currentSettings, saveSettings}}
      >
        {children}
      </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;
export default SettingsContext;

export const useSettings = () => {
  const context = useContext(SettingsContext);
  return context;
};