import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { analyticsEventInChatContext, RoleplayEvent } from "../../../analytics/analytics";
import { useSettings } from "../../../context/Settings";
import { Icon } from "../../Layout/Icon/Icon";
import { useRoleplayContext } from "../roleplay.helpers";
import { extendedAnswerType } from "./Chat";
import { DialogResult } from "./DialogResult";

export interface ActiveAnswerProps {
  activePatientsAnswer: extendedAnswerType;
  onQuestionClick: (
    nextPatientsAnswerID: string,
    clickedQuestionID: string,
  ) => void;
  restartRoleplay: () => void;
  isWaiting: boolean;
  setVideo: (videoId: string | null, videoUrl: string | null) => void;
  setWaiting: () => void;
  isMicEnabled: boolean;
  isReplaying: boolean;
  setIsReplaying: React.Dispatch<boolean>;
  showResultFromQuestion?: "success" | "fail"
}

export const ActiveAnswer: React.FunctionComponent<ActiveAnswerProps> = React.memo(({
  activePatientsAnswer,
  onQuestionClick,
  restartRoleplay,
  isWaiting,
  setVideo,
  setWaiting,
  isMicEnabled,
  isReplaying,
  setIsReplaying,
  showResultFromQuestion
}) => {
  const [showHints, setShowHints] = useState(false)
  const [clickedID, setClickedID] = useState<string | null>(null);

  const { t } = useTranslation();
  const { settings } = useSettings();
  const { rolePlayData, setShowAnswerVideo } = useRoleplayContext()
  const questionsMap = rolePlayData.roleplay.questions;

  const showAnswer = isWaiting || isReplaying;
  const questionsTexts = activePatientsAnswer.questions?.map(questionID => questionsMap[questionID]) || [];
  const anyHints = !!questionsTexts.map(el => el.additionalInfo).filter(el => !!el).length;
  const showResult = (activePatientsAnswer.result && showAnswer) || showResultFromQuestion;
  const result = activePatientsAnswer.result || showResultFromQuestion
  
  const questionRef = useRef<HTMLDivElement>(null);
  const answersRef = useRef<HTMLDivElement>(null);
  const buttonOverrideStyle = settings.isSafari ? {marginTop: "5px"} : {};
  const buttonOverrideStyle2 = settings.isSafari ? {marginTop: "3px"} : {};

  useEffect(
    () => {
      questionRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    },
    [activePatientsAnswer, questionRef, isWaiting]
  );

  const replay = () => {
    setIsReplaying(true);
    setShowAnswerVideo(true);
    setVideo(activePatientsAnswer.videoID, activePatientsAnswer.videoUrl);

    const videoElement = document.getElementById('answer-video-div')?.querySelector('video');
    if (videoElement) {
      videoElement.pause();
      videoElement.currentTime = 0;
      videoElement.play();
    }

    analyticsEventInChatContext(RoleplayEvent.replay)
  }

  const skip = () => {
    setVideo(rolePlayData.waitingVideoID, rolePlayData.waitingVideoUrl); // to mute player working in the background
    setWaiting()
    analyticsEventInChatContext(RoleplayEvent.skip_answer)
  }

  const onShowHints = () => { 
    if (!showHints) {
      answersRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
      analyticsEventInChatContext(RoleplayEvent.quick_tip)
    }
    setShowHints(!showHints)
  }

  useEffect(() => {
    setShowHints(false)
  }, [activePatientsAnswer]);

  return (
    <>
      <div className="dialog-box" ref={questionRef} key={activePatientsAnswer.text}>
        <div className="balloons-wrapper">
          {!showAnswer && 
          <>
            <button className="button skip-button" onClick={skip}>
              <Icon name="close" fill="none" size={40} stroke="#1E223C" />
              <span style={buttonOverrideStyle}>
                {t("roleplay.skip_btn")}
              </span>
            </button>
            <p className="dialog-balloon dialog-balloon--waiting scale-up-center">
              <span>. </span>
              <span>. </span>
              <span>. </span>
            </p>
          </>}

          {showAnswer && 
          <>
            <button className="button replay-button" onClick={replay}>
              <Icon name="replay" size={30} height={30} stroke="#1E223C" />
            </button>
            <p className="dialog-balloon dialog-balloon--answer scale-up-center">
              {activePatientsAnswer.text}
            </p>
          </>}
        </div>
        {activePatientsAnswer.questions && showAnswer && (
          <div className="balloons-wrapper balloons-wrapper--questions slide-top">
            <p className="balloon-info" ref={answersRef}>
            {isMicEnabled ? t("roleplay.balloon_info_mic") : t("roleplay.balloon_info")}
            {anyHints && <button className={'button'} onClick={onShowHints}>
              <span style={buttonOverrideStyle2}>{t('roleplay.quick_tip')}</span>
              <Icon name="info" fill="#1E223C" size={24} />
            </button>}
            </p>
            <ol className="balloons-active__list">
              {activePatientsAnswer.questions.map((questionID) => (
                <li 
                  key={questionID}
                  className={showResultFromQuestion && questionID !== clickedID ? 'question-disabled' : ''}
                  >
                  <button
                    className="button dialog-balloon dialog-balloon--clickable"
                    onClick={() => {
                      if (showResultFromQuestion) return;
                      setClickedID(questionID)
                      onQuestionClick(
                        questionsMap[questionID].answerID,
                        questionID,
                      )
                    }}
                  >
                    {questionsMap[questionID].text}
                  </button>
                  {questionsMap[questionID].additionalInfo && showHints && (
                    <div className="question-info">
                      <small>{questionsMap[questionID].additionalInfo}</small>
                    </div>
                  )}
                </li>
              ))}
            </ol>
          </div>
        )}

      {showResult && result && <DialogResult 
        result={result}
        restartRoleplay={restartRoleplay}
        afterQuestionResult={!!showResultFromQuestion}
      />}
      </div>
    </>
  );
});
