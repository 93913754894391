import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { BackgroundGradient } from "./components/BackgroundGradient/BackgroundGradient";
import { SettingsProvider, defaultSettings } from "./context/Settings";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

ReactDOM.render(
  <BrowserRouter basename={baseUrl ? baseUrl : undefined}>
    {/* Elements used/generated by external oneTrust script - have to be outside dimountable react components */}
    <div id="one-trust-container" style={{display: 'none'}}>
      <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</button>
      <div id="ot-sdk-cookie-policy"></div>
    </div>
    <SettingsProvider settings={defaultSettings}>
      <App />
    </SettingsProvider>
    <BackgroundGradient/>
  </BrowserRouter>,
  rootElement
);