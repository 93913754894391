import React from "react";
import { Icon } from "../../Layout/Icon/Icon";

export interface StartingQuestionProps {
  startingQuestionInfo: string;
  startingQuestionActive: boolean;
  questionText: string;
  onButtonClick: (fromMic?: boolean) => void;
}

export const StartingQuestion: React.FunctionComponent<StartingQuestionProps> =
  ({
    startingQuestionInfo,
    startingQuestionActive,
    questionText,
    onButtonClick,
  }) => (
    <div className="start-question__wrapper">
      <div className="balloons-wrapper balloons-wrapper--questions balloons-wrapper--start scale-up-center">
        <p className="dialog-info">
          <Icon name="info" fill="#1E223C" size={28} />
          {startingQuestionInfo}
        </p>
        <button
          className={`dialog-balloon ${startingQuestionActive ? 'dialog-balloon--clickable' : ''} button`}
          onClick={() => onButtonClick(false)}
          disabled={!startingQuestionActive}
        >
          {questionText}
        </button>
      </div>
    </div>
  );
