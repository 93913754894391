import React, { Suspense, useEffect } from 'react';
import { Route } from 'react-router';
import { Home } from './components/Home/Home';
import { ChooseLanguage } from './components/ChooseLanguage/ChooseLanguage';
import { PreRoleplay } from './components/PreRoleplay/PreRoleplay';
import { Navbar } from './components/Navbar/Navbar';
import { Redirect, Switch } from 'react-router-dom';
import i18n, { supportedLanguages } from './i18n/i18n';
import { ReferencesPage } from './components/StaticPages/ReferencesPage';
import { AboutPage } from './components/StaticPages/AboutPage';
import { LoginPage } from './components/LoginPage/LoginPage';
import PrivateRoute from './components/Authentication/PrivateRoute';

import './styles/App.scss';
import { useSettings } from './context/Settings';
import { CookiePolicyPage } from './components/StaticPages/CookiePolicyPage';
import { NotSupportedBrowser } from './components/NotSupportedBrowser/NotSupportedBrowser';

const App = () => {
  const { settings } = useSettings();
  const langParam = `/:lang(${supportedLanguages.join('|')})/`;

  useEffect(() => {
    if (settings.language) i18n.changeLanguage(settings.language);
  }, [settings.language]);
  console.log(settings);

  return (
      <>
        <Suspense fallback={'loading'}>
          <NotSupportedBrowser>
            <>
              <Navbar />
              <Switch>
                <Route exact path="/" component={ChooseLanguage} />

                {settings.isAuthEnabled ?? (
                    <>
                      <Route exact path={'/login'} component={ChooseLanguage} />
                      <Route path={'/login' + langParam} component={LoginPage} />
                    </>
                )}

                <PrivateRoute path={'/roleplay'} component={PreRoleplay} />
                <PrivateRoute path={'/references'} component={ReferencesPage} />
                <PrivateRoute path={'/about'} component={AboutPage} />
                <PrivateRoute exact path={'/home'} component={Home} />
                <PrivateRoute path={'/cookie-policy'} component={CookiePolicyPage} />

                <Route path="/*">
                  <Redirect to="/" />
                </Route>
              </Switch>
            </>
          </NotSupportedBrowser>
        </Suspense>
      </>
  );
};

export default App;
