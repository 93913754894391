
import i18n, { InitOptions } from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

export enum LanguageCodes {
  en = "en",
  fi = "fi",
  sv = "sv",
  da = "da",
}

export const supportedLanguages = Object.values(LanguageCodes);

const detectorOptions: DetectorOptions = {
  order: ["navigator"],
}

const initOptions: InitOptions = {
  whitelist: supportedLanguages,
  detection: detectorOptions,
  interpolation: {
    escapeValue: false
  }
}

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(initOptions)

export const getSpeechRecognitionLangCode = (langCode: string): string => {
  switch (langCode) {
    case LanguageCodes.en: return 'en-US'
    case LanguageCodes.fi: return 'fi-FI'
    case LanguageCodes.sv: return 'sv-SE'
    case LanguageCodes.da: return 'da-DK'
    default: return 'en-US'
  }
}

export const getCountryName = (langCode: string): string => {
  switch (langCode) {
    case LanguageCodes.en: return 'England'
    case LanguageCodes.fi: return 'Finland'
    case LanguageCodes.sv: return 'Sweden'
    case LanguageCodes.da: return 'Denmark'
    default: return 'England'
  }
}

export default i18n