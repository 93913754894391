import React, { useCallback, useEffect, useState } from "react";
import { VideoPlayer } from "../VideoPlayer/VideoPlayer";
import { Popup } from "../Popup/Popup";
import { Icon } from "../Layout/Icon/Icon";
import { Roleplay } from "../Roleplay/Roleplay";
import { Description } from "./components/Description";
import { ControlButton } from "./components/ControlButton";
import { preRoleplayDataTypes } from "./types";
import { useTranslation } from "react-i18next";
import axios, { AxiosResponse } from "axios";
import { useSettings } from "../../context/Settings";
import { getAuthenticationToken } from "../../utils/authentication";

export const PreRoleplay: React.FunctionComponent = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [rolePlaysData, setRoleplaysData] = useState<preRoleplayDataTypes[] | null>(null);
  const [activeVideoIndex, setActiveVideoIndex] = useState<number>(0);
  const [playPressed, setPlayPressed] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [hearStoryCanPlay, setHearStoryCanPlay] = useState<boolean>(false);
  const [isConversationActive, setConversationActive] = useState<boolean>(false);
  const [waitingVidCanPlay, setwaitingVidCanPlay] = useState(false);

  const { t } = useTranslation();
  const { settings, saveSettings } = useSettings();
  const language = settings.language;
  const buttonOverrideStyle = settings.isSafari ? { marginTop: "2px" } : {};
  console.log('roleplay page', rolePlaysData)

  useEffect(() => {
    if (!language) return;
    const authToken = getAuthenticationToken();
    const fetchRoleplaysData = async () => {
      const { data }: AxiosResponse<preRoleplayDataTypes[]> = await axios.get(
        `/api/pre-roleplay?language=${language}`, {
          headers: settings.isAuthEnabled ? { 'Authorization': `Bearer ${authToken}`} : {}
        }
      );
      setRoleplaysData(data);
    };

    fetchRoleplaysData();
  }, [language]);

  useEffect(() => {
    setPlayPressed(false);
    setActiveVideoIndex(0);
  }, [activeIndex]);

  useEffect(() => {
    if (!playPressed) { setHearStoryCanPlay(false);}
    saveSettings({ showNav: !playPressed });
  }, [playPressed, saveSettings]);

  const onRoleplayFinish = useCallback(
    () => setConversationActive(false), []
  )

  if (rolePlaysData === null) return <></>;

  const roleplay = rolePlaysData[activeIndex];

  return (
    <>
      {isConversationActive ? (
        <Roleplay
          activeRoleplayID={roleplay.id}
          setConversationActive={setConversationActive}
          onRoleplayFinish={onRoleplayFinish}
        />
      ) : (
        <>
          <div className="pre-roleplay">
            <div className={`pre-roleplay__about ${playPressed ? "pre-roleplay__about--played" : ""}`}>
              {!playPressed && (
                <>
                  <div className="pre-roleplay__column pre-roleplay__column--left">
                    {activeIndex !== 0 && (
                      <ControlButton
                      onButtonClick={() => setActiveIndex(activeIndex - 1)}
                      buttonClassName="column-controls__button--left"
                      ariaLabel={t("icons.previous")}
                      />
                    )}
                  </div>

                  <div className="about-btn-wrapper">
                    <button
                      className="button about-person__button about-person__button--mobile"
                      onClick={() => {
                        setPlayPressed(true);
                        setActiveVideoIndex(1);
                      }}
                      >
                      <span style={buttonOverrideStyle}>
                        {roleplay.story}
                      </span>
                      <Icon name="play" size={24} fill="#1E223C" />
                    </button>
                  </div>

                  <div className="pre-roleplay__column">
                    {activeIndex !== rolePlaysData.length - 1 && (
                      <ControlButton
                        onButtonClick={() => setActiveIndex(activeIndex + 1)}
                        ariaLabel={t("icons.next")}
                      />
                    )}
                  </div>
                </>
              )}

              <div
                className={`about-person ${
                  playPressed ? "played-video" : "default-video" 
                } ${
                  waitingVidCanPlay ? "default-video--visible" : ''
                }`}
              >
                <VideoPlayer
                  accountId={"3845398857001"}
                  videoId={roleplay.videoIds[0]}
                  videoUrl={roleplay.videoUrls[0]}
                  loop={true}
                  unmuted={false}
                  onCanPlay={() => setwaitingVidCanPlay(true)}
                />
              </div>

              {playPressed && (
                <div
                  className={`about-person played-video hear-story-vid ${
                    hearStoryCanPlay ? "hear-story-vid--show" : ""
                  }`}
                >
                  <VideoPlayer
                    accountId={"3845398857001"}
                    videoId={roleplay.videoIds[1]}
                    videoUrl={roleplay.videoUrls[1]}
                    loop={false}
                    unmuted={true}
                    onCanPlay={() => setHearStoryCanPlay(true)}
                  />
                </div>
              )}
            </div>

            <div className="pre-roleplay__container">
              <Description
                data={rolePlaysData}
                activeIndex={activeIndex}
                activeVideo={activeVideoIndex}
                playPressed={playPressed}
                setActiveVideo={setActiveVideoIndex}
                setPlayPressed={setPlayPressed}
                setIsPopupOpen={setIsPopupOpen}
                setConversationActive={setConversationActive}
              />
            </div>

          </div>
          {isPopupOpen && <Popup onCloseClick={() => setIsPopupOpen(false)} trainingVideoID={roleplay.trainingVideoID} trainingVideoUrl={roleplay.trainingVideoUrl}/>}
        </>
      )}
    </>
  );
};
