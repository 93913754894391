import axios from "axios";
import Cookie from "universal-cookie";
import { getAuthenticationToken } from "./authentication";

export async function getTokenOrRefresh() {
  const cookie = new Cookie();
  const speechToken = cookie.get("speech-token");

  const speechConfigURL = "/api/config/speech";
  const token = getAuthenticationToken();

  const {
    data: { key, region },
  } = await axios.get(speechConfigURL, { headers: { 'Authorization': `Bearer ${token}`}}).catch((err) => err);

  const speechKey = key;
  const speechRegion = region;

  const headers = {
    headers: {
      "Ocp-Apim-Subscription-Key": speechKey,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const url = `https://${speechRegion}.api.cognitive.microsoft.com/sts/v1.0/issueToken`;

  if (speechToken === undefined) {
    try {
      const res = await axios.post(url, null, headers);

      const token = res.data;
      const region = speechRegion;
      cookie.set("speech-token", region + ":" + token, {
        maxAge: 180,
        path: "/",
      });

      console.log("Token fetched from back-end: " + token);
      return { authToken: token, region: speechRegion };
    } catch (err) {
      console.log(err.response.data);
      return { authToken: null, error: err.response.data };
    }
  } else {
    console.log("Token fetched from cookie: " + speechToken);
    const idx = speechToken.indexOf(":");

    return {
      authToken: speechToken.slice(idx + 1),
      region: speechToken.slice(0, idx),
    };
  }
}
