import React, { SetStateAction, useContext } from "react";
import { RoleplayEvent } from "../../analytics/analytics";
import { roleplayDataTypes } from "./types";

export const chooseResponseIndex = (
  voiceResponse: string, 
  responsesToChoose: string[]
): { foundIndex: number; isComplete?: boolean } | null => {
  const responseVectors = vectorizeStrings([voiceResponse, ...responsesToChoose])
  const voiceVector = responseVectors.shift();
  if (!voiceVector) throw new Error('Error during sentence vectorization')

  const cosineSims = responseVectors.map(respV => cosineSimilarity(voiceVector, respV))
  const maxSimilarity = Math.max(...cosineSims);

  if (maxSimilarity < 0.3) return null;
  const foundIndex = cosineSims.indexOf(maxSimilarity)
  const targetLength = responsesToChoose[foundIndex].length;
  const isComplete = (voiceResponse.length / targetLength > 0.8 && targetLength - voiceResponse.length < 50) ? true : false;
  
  return { foundIndex, isComplete };
}

export const vectorizeStrings = (strings: string[]): number[][] => {
  const bagOfWords = new Set<string>();
  const stringsMatrix = strings.map(string => {
      const result = cleanInputString(string);
      result.forEach(word => bagOfWords.add(word))
      return result
    }
  );
  
  const bagOfWordsArray = Array.from(bagOfWords);
  const vectors = stringsMatrix.map(stringArray => createVector(stringArray, bagOfWordsArray))

  return vectors
}

export const createVector = (sentenceArray: string[], bagOfWordsArray: string[]) => {
  const result = bagOfWordsArray.map(el => 0);
  for (let word of sentenceArray) {
    const index = bagOfWordsArray.findIndex(el => el === word)
    result[index] += 1
  }

  return result
}

export const cleanInputString = (string: string): string[] => {
  const result = string
    .replace(/[.,\/#!$%\^&\*;:{}=?\-_`~()]/g, "")
    .replace(/\s\s+/g, ' ')
    .toLowerCase()
    .split(' ');

  return result;
}

//Math funcitons 
function dotp(x: number[], y: number[]) {
  function dotp_sum(a: number, b: number) {
    return a + b;
  }
  function dotp_times(a: number, i: number) {
    return x[i] * y[i];
  }
  return x.map(dotp_times).reduce(dotp_sum, 0);
}

function cosineSimilarity(A: number[], B: number[]){
  let similarity = dotp(A, B) / (Math.sqrt(dotp(A, A)) * Math.sqrt(dotp(B, B)));
  return similarity;
}

export const promptUserAllowMic = async () => {
  try {
    await navigator.mediaDevices.getUserMedia({audio: true})
  } catch (e) { 
    console.log(e)
  }
}

type RoleplayContextType = {
  rolePlayData: roleplayDataTypes;
  setRoleplayID: React.Dispatch<SetStateAction<string>>;
  setShowAnswerVideo: React.Dispatch<SetStateAction<boolean>>;
  onRoleplayFinish: () => void;
}

export const RoleplayContext = React.createContext<RoleplayContextType>({
  rolePlayData: {} as any, // this component assures that child components won't render when data is null
  setRoleplayID: {} as any,
  setShowAnswerVideo: {} as any,
  onRoleplayFinish: {} as any
});

export const useRoleplayContext = () => {
  const context = useContext(RoleplayContext);
  return context;
};