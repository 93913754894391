import Cookie from "universal-cookie";

export type LoginResponse = {
  countryCode: string,
  fullName: string,
  token: string,
  id: string,
}

const tokenValidFor = 20; //in days
const authIdentifier = 'auth-token';

export const setAuthToken = (token: string) => {
  const cookie = new Cookie();
  const today = new Date();
  const expirationDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + tokenValidFor);
  cookie.set(authIdentifier, token, {
    expires: expirationDate
  });
}

export const getAuthenticationToken = (): string | null => {
  const cookie = new Cookie();
  return cookie.get(authIdentifier);
}

export const clearAuthToken = () => {
  const cookie = new Cookie();
  cookie.remove(authIdentifier);
}