import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { StaticPage } from "./StaticPage";

export const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <StaticPage>
      <h1>{t("about.heading")}</h1>
      <h3>{t("about.pitch")}</h3>
      <p>
        <Trans>{t("about.description")}</Trans>
      </p>
      <p>{t("about.small")}</p>
    </StaticPage>
  );
};
