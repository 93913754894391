import React from "react";
import sanitizeHtml from "sanitize-html";

export const Sanitize = ({ html }: any) => {
  const clean = sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["p", "span", "div"]),
    allowedClasses: {
      p: ["text"],
      div: ["results-container"],
    },
  });

  return (
    <div
      className="sanitized-html"
      dangerouslySetInnerHTML={{ __html: clean }}
    />
  );
};
