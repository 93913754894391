import React from "react";
import VideoJS from "./VideoJS";
import { useSettings } from "../../context/Settings";
import { getSpeechRecognitionLangCode } from "../../i18n/i18n";

interface VideoPlayerProps {
  accountId: string;
  videoId: string;
  videoUrl: string;
  loop?: boolean;
  onEnded?: () => void;
  onCanPlay?: () => void;
  unmuted?: boolean;
}

export const VideoPlayer: React.FunctionComponent<VideoPlayerProps> = ({
  accountId,
  videoId,
  videoUrl,
  loop,
  onEnded,
  onCanPlay,
  unmuted,
}) => {
  const playerRef = React.useRef(null);
  const { settings } = useSettings();
  const language = settings.language;
  const languageCode = getSpeechRecognitionLangCode(language || "en");

  const videoJsOptions = {
    autoplay: true,
    playsinline: true,
    controls: true,
    responsive: true,
    fluid: true,
    loop: loop,
    muted: !unmuted,
    subtitles: true,
    sources: [
      {
        src: videoUrl,
        type: "application/x-mpegURL",
      },
    ],
  };

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    const settings = player.textTrackSettings;
    settings.setValues({
      backgroundColor: "#000",
      backgroundOpacity: "0",
    });
    settings.updateDisplay();

    if (onEnded) player.on("ended", onEnded);
    if (onCanPlay) player.on("canplay", () => {
      onCanPlay();
      
      var tracks = player.textTracks();
      for (var i = 0; i < tracks.length; i++) {
        var track = tracks[i];
        if (track.kind === 'captions' || track.kind === 'subtitles') {
          track.mode = 'showing';
        }
      }
    });
  };

  return <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />;
};
