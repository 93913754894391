import React, { useState, useEffect, useRef, useLayoutEffect, Suspense } from 'react'
import AnimationCanvas from '../WaveAnimation/WaveAnimationThree'
import { Overlay } from './components/LandingPageOverlay'
import { SyncedText } from './components/SyncedText'
import { mouseMovementTracker } from '../WaveAnimation/waveAnimation.helpers'
import { addEffect } from '@react-three/fiber'
import { LanguageCodes } from '../../i18n/i18n'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useSettings } from '../../context/Settings'

export let audioData = new Uint8Array(1024);
export let plays = false;
export let setPlays = (p: boolean) => plays = p;

export type Subtitle = {
  startTime: number;
  text: string;
}

type HomeProps = {
  lang?: LanguageCodes
}

export const Home: React.FunctionComponent<HomeProps> = () => {
  const [ audioCanPlay, setAudioCanPlay ] = useState(false);
  const [ playPressed, setPlayPressed ] = useState(false);
  const [ subtitlesData, setSubtitlesData ] = useState<Subtitle[] | null>(null);
  const { settings } = useSettings();
  const lang = settings.language;
  const audioRef = useRef<HTMLMediaElement | null>(null);

  const history = useHistory();

  const onCanPlay = () => { setAudioCanPlay(true) }
  const onEnded = () => {
    plays = false;
    setPlayPressed(false);
    history.push('/roleplay')
  }

  const getCurrentTime = () => {
    if (!audioRef.current) return null;
    return audioRef.current.currentTime
  }

  useLayoutEffect(() => {
    const createAnalyser = () => {
      if (playPressed && audioRef.current) {
        if (!audioCanPlay) audioRef.current.load();
        const typedWindow = window as any;
        const context = new (typedWindow.AudioContext || typedWindow.webkitAudioContext)()
        const analyser = context.createAnalyser()
        analyser.fftSize = 2048
        const data = new Uint8Array(analyser.frequencyBinCount)
        const source = context.createMediaElementSource(audioRef.current);
        source.connect(analyser)
        analyser.connect(context.destination)

        addEffect(() => {
          analyser.getByteFrequencyData(data)
          audioData = data;
        })

        audioRef.current.play();
      }
    }

    if (!audioRef.current) {
      setTimeout(() => createAnalyser(), 1000);
      return;
    } else createAnalyser();

  }, [playPressed])

  useEffect(() => {
    document.addEventListener("pointermove", mouseMovementTracker)

    const getData = async () =>{
      const response = await axios.get(
          `/audio/subtitles/${lang}.json`,
          {
            headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          }
      )

      if (response.data) {
        setSubtitlesData(response.data)
      }
    }
    getData();

    return () => {
      document.removeEventListener("pointermove", mouseMovementTracker)
    }
  }, [])

  const getAudioFile = () => {
    switch(lang) {
      case LanguageCodes.fi:
        return "audio-fi.wav"
      case LanguageCodes.sv:
        return "audio-sv.wav"
      case LanguageCodes.da:
        return "audio-da.wav"
      case LanguageCodes.en:
        return "audio.mp3"
      default:
        return "audio.mp3"
    }
  }

  const audioSource = getAudioFile();

  return (
      <>
        <audio onCanPlay={onCanPlay} onEnded={onEnded} ref={audioRef}>
          <source src={`/audio/${audioSource}`} type="audio/mpeg"/>
          Your browser does not support the audio tag.
        </audio>
        {!playPressed && <Overlay
            setPlay={setPlayPressed}
            audioCanPlay={audioCanPlay}
            audioPlays={playPressed}
            audioRef={audioRef}
        />}
        <Suspense fallback={null}>
          <AnimationCanvas />
        </Suspense>
        {playPressed && subtitlesData && (<>
          <SyncedText getTime={getCurrentTime} subtitles={subtitlesData}/>
        </>)}
      </>
  )
}
