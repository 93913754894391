import React from "react";
import { Icon } from "../Layout/Icon/Icon";
import { VideoPlayer } from "../VideoPlayer/VideoPlayer";

interface PopupProps {
  onCloseClick: () => void;
  trainingVideoID: string;
  trainingVideoUrl: string;
}

export const Popup: React.FunctionComponent<PopupProps> = ({
  onCloseClick,
  trainingVideoID,
  trainingVideoUrl
}) => (
  <div className="popup">
    <button
      className="button close-button close-button--popup"
      onClick={onCloseClick}
      aria-label="Close popup"
    >
      <Icon name="close" stroke="#FFF" fill="none" size={45} />
    </button>
    <div className="popup__video">
      <VideoPlayer
        accountId={"3845398857001"}
        videoId={trainingVideoID}
        videoUrl={trainingVideoUrl}
        unmuted={true}
      />
    </div>
  </div>
);
