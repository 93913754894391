import React from 'react'

export const BackgroundGradient = () => {
  return (
    <div className="background">
      <div className="background__gradient"></div>
      <div className="background__dot-container">
        <div className="background__moving-dot"></div>
        <div className="background__moving-dot background__moving-dot--secondary"></div>
      </div>
    </div>
  )
}