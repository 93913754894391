import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSettings } from '../../context/Settings';
import { getCountryName, LanguageCodes, supportedLanguages } from '../../i18n/i18n';
import { Icon } from '../Layout/Icon/Icon';


const Dropdown = () => {
  const [isListOpen, setIsListOpen] = useState(false);
  const history = useHistory();
  const { i18n } = useTranslation();
  const { settings, saveSettings } = useSettings();

  //Temporary - danish version is blocked. Uncomment first line, delete second when all language versions are ready
  // const options = supportedLanguages.filter(el => el !== 'en');
  const options = supportedLanguages.filter(el => ['fi', 'sv', 'da'].includes(el));
  const toggleList = () => setIsListOpen(!isListOpen)

  const selectLang = (lang: string) => {
    i18n.changeLanguage(lang)
    saveSettings({ language: lang as LanguageCodes })
    settings.isAuthEnabled ? history.push(`/login/${lang}`) : history.push('/home')
  }

  const selectOverrideStyle = settings.isSafari ? {'paddingTop': '4px'} : {};

  return (
      <div className="dd-wrapper">
        <div className="dd-content">
          <button
              type="button"
              className="dd-header"
              onClick={toggleList}
          >
            <div className="dd-header__title" style={selectOverrideStyle}>
              {'Select your country'}
              <Icon name='rectangle' stroke="#1E223C" fill="none" size={20}
                    className={`dd-arrow ${isListOpen ? 'dd-arrow--expanded' : ''}`}/>
            </div>
          </button>
          {isListOpen && (
              <div
                  role="list"
                  className="dd-list"
              >
                {options.map((item: LanguageCodes) => (
                    <button
                        type="button"
                        className="dd-list-item"
                        style={selectOverrideStyle}
                        key={item}
                        onClick={() => selectLang(item)}
                    >
                      {getCountryName(item)}
                    </button>
                ))}
              </div>
          )}
        </div>
      </div>
  )
}

export default Dropdown
