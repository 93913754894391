import React, { useCallback, useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { analyticsEventInChatContext, RoleplayEvent } from "../../../analytics/analytics";
import { useSettings } from "../../../context/Settings";
import { Icon } from "../../Layout/Icon/Icon";
import { Sanitize } from "../../Sanitize/Sanitize";
import { useRoleplayContext } from "../roleplay.helpers";

interface Props {
  result: "success" | "fail";
  restartRoleplay: () => void;
  afterQuestionResult: boolean;
}

export const DialogResult: React.FunctionComponent<Props> = ({
  result,
  restartRoleplay,
  afterQuestionResult
}) => {
  const { t } = useTranslation();
  const { settings } = useSettings();

  const { rolePlayData, setRoleplayID, onRoleplayFinish } = useRoleplayContext();
  const continuationRoleplayID = rolePlayData.roleplay.continuationRoleplayID;
  const buttonOverrideStyle = settings.isSafari ? { marginTop: "5px" } : {};

  const ref = useRef<HTMLDivElement>(null);

  const onContinueToNextPart = () => {
    if (continuationRoleplayID) setRoleplayID(continuationRoleplayID)
  }

  const onFinishResultClickAnalyzed = useCallback(
    () => {
      onRoleplayFinish();
      analyticsEventInChatContext(RoleplayEvent.finish);
    },
    [onRoleplayFinish]
  )

  const onReplay = () => {
    if (rolePlayData.mainPartID) {
        analyticsEventInChatContext(RoleplayEvent.restart);
        setRoleplayID(rolePlayData.mainPartID)
    } else {
      restartRoleplay();
    }
  }

  useLayoutEffect(
    () => {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    },
    []
  );

  return (
    <div className="dialog-result" ref={ref} style={afterQuestionResult ? {marginTop: "-150px"} : {}}>
      {result === "success" && (
        <>
          <h2 className="result-header">{t("roleplay.result_success")}</h2>
          <Sanitize html={rolePlayData.roleplay.successRoleplay} />
          {!continuationRoleplayID 
          ? <div className="result-buttons">
              <button
                className="button result-button result-button--restart"
                onClick={onReplay}
              >
                <Icon name="replay" size={30} stroke="#1E223C" />
                <span style={buttonOverrideStyle}>
                  {t("roleplay.result_restart")}
                </span>
              </button>
              <button
                className="button result-button"
                onClick={onFinishResultClickAnalyzed}
                >
                <span style={buttonOverrideStyle}>
                  {t("roleplay.result_finish")}
                </span>
              </button>
            </div>
          : <div className="result-buttons">
              <button
                className="button result-button"
                onClick={onContinueToNextPart}
                >
                <Icon
                  name="union"
                  size={24}
                  height={24}
                  stroke="#F0ADAB"
                />
                <span style={buttonOverrideStyle}>
                  {t("roleplay.result_continue")}
                </span>
              </button>
            </div>
          }
        </>
      )}

      {result === "fail" && (
        <>
          <h2 className="result-header">{t("roleplay.result_failed")}</h2>
          <Sanitize html={rolePlayData.roleplay.failRoleplay} />
          <div className="result-buttons">
            <button
              className="button result-button result-button--restart"
              onClick={onFinishResultClickAnalyzed}
            >
              <span style={buttonOverrideStyle}>
                {t("roleplay.result_finish")}
              </span>
            </button>
            <button className="button result-button" onClick={restartRoleplay}>
              <Icon name="replay" size={30} stroke="#F0ADAB" />
              <span style={buttonOverrideStyle}>
                {t("roleplay.result_again")}
              </span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};
