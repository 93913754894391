import React, { useState, useEffect } from 'react'
import DOMPurify from 'dompurify';

type SyncedTextSlideProps = {
  word: string
  index: number
}

export const SyncedWord: React.FunctionComponent<SyncedTextSlideProps> = ({ word, index }) => {
  const [show, setShow] = useState(false);
  const delay = (index + 1) * 70
  const showClass = show ? 'subtitles__word--show' : '';

  useEffect(() => {
    setTimeout(() => setShow(true), delay)
  }, [setShow, index, delay]);

  return (
      <span className={`subtitles__word ${showClass}`} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(word) + ' '}}></span>
  )
}
