import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StaticPage } from "./StaticPage";

export const ReferencesPage = () => {
  const [references, setReferences] = useState([]);
  const { i18n, t } = useTranslation();
  const lang = i18n.language;

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(`/references/${lang}.json`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (response.data) {
        setReferences(response.data);
      }
    };
    getData();
  }, [lang]);

  return (
    <StaticPage>
      <h1>{t("references.header")} </h1>
      {references.map((el, i) => (
        <p key={i}>{el}</p>
        ))}
    </StaticPage>
  );
};
