import { Color } from "three";

export const ANIMATION_CONFIG = {
  startColor: [255, 255, 255],
  endColor: [255, 255, 255],
  nLines: 60,
  nSoundSamples: 512,
  spaceBetweenLines: 0.05,
  particleWidth: 0.030,
  particleHeight: 0.004,
}

export const mouseEvent: { isDefined: boolean, event: PointerEvent | null} = {
  isDefined: true,
  event: null
};

export const mouseMovementTracker = (e: PointerEvent) => {
  mouseEvent.event = e;
}

const createColorArray = (startColor: number[], endColor: number[], nLines: number): THREE.Color[] => {
  const colorArray = [];
  const deltaR = (endColor[0] - startColor[0]) / nLines;
  const deltaG = (endColor[1] - startColor[1]) / nLines;
  const deltaB = (endColor[2] - startColor[2]) / nLines;  

  for (let i = 0; i < nLines; i++) {
    let colorR = Math.round(startColor[0] + i * deltaR);
    let colorG = Math.round(startColor[1] + i * deltaG);
    let colorB = Math.round(startColor[2] + i * deltaB);
    colorArray.push(new Color( `rgb(${colorR}, ${colorG}, ${colorB})`))
  }

  return colorArray;
}

export const colorArray: THREE.Color[] = createColorArray(
  ANIMATION_CONFIG.startColor, 
  ANIMATION_CONFIG.endColor,
  ANIMATION_CONFIG.nLines 
);

export const transformToBellCurve = (lineIndex: number, maxLineIndex: number) => {
  // scale to [0:3] range
  const targetRangeWidth = 3
  let x = lineIndex * targetRangeWidth / maxLineIndex;
  // center around 0
  x -= targetRangeWidth/2;
  // return transformed value 
  return 1/(1 + x**2)**(3/2)
}

