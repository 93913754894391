import React, { useEffect } from 'react'
import { setPlays } from '../Home'
import headphones from '../../../assets/headphones.svg';
import { Trans, useTranslation } from 'react-i18next';
import { useSettings } from '../../../context/Settings';
import { ReactComponent as DialogLogo } from '../../../assets/dialog-logo.svg';

type AnimationOverlayProps = {
  setPlay: React.Dispatch<boolean>
  audioCanPlay: boolean,
  audioPlays: boolean,
  audioRef: React.MutableRefObject<HTMLMediaElement | null>;
};

export const Overlay: React.FunctionComponent<AnimationOverlayProps> = ({
  setPlay,
  audioCanPlay,
  audioRef
}) => {
  const { t } = useTranslation();
  const { settings, saveSettings } = useSettings();

  useEffect(() => {
    saveSettings({showNavLogo: false})
    return () => {
      saveSettings({showNavLogo: true})
    };
  }, [saveSettings]);

  const a11yHidden = settings.showMenu || !!settings.staticPage;

  const play = () => {
    setPlay(true);
    setPlays(true);
  }

  //Fix for font rendering issue in safari
  const buttonStyleOverride = settings.isSafari ? { paddingTop: "20px"} : {}

  return (
    <>
      <div className={`animation-overlay`} aria-hidden={a11yHidden}>
        <div className="intro-center">
          <DialogLogo className="intro-center__logo" aria-label="Dialog Logo"/>
          <p>
            <Trans>{t('home.tool_description')}</Trans>
          </p>
          <button 
            className="intro-center__start-btn" 
            onClick={play}
            disabled={a11yHidden}
            style={buttonStyleOverride}
          >
            {t('home.start_experience')}
        </button>
        </div>
        <div className="headphones-info">
          <img className="headphones-info__logo" src={headphones} alt="headphones icon" />
          <p>{t('home.headphones_recommended')}</p>
        </div>
      </div>
    </>
  )
}
