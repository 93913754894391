import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { customPushToDataLayer } from "../../../analytics/analytics";
import { useSettings } from "../../../context/Settings";
import { clearAuthToken } from "../../../utils/authentication";
import { BackgroundGradient } from "../../BackgroundGradient/BackgroundGradient";
import { Icon } from "../../Layout/Icon/Icon";
import { privacyPolicyLink, termsOfUseLink } from "./menu.helpers";
import {useHistory} from "react-router";

export const Menu = () => {
  const { settings, saveSettings } = useSettings();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const username = settings.userName;
  const buttonOverrideStyle = settings.isSafari
      ? { marginTop: "5px", marginLeft: "5px" }
      : {};

  const onClickOut = (engTitle?: string, translatedTitle?: string) => {
    saveSettings({ showMenu: false });
    if (engTitle && translatedTitle) {
      customPushToDataLayer({
        event: 'menu_click',
        menu_title: engTitle,
        menu_title_local: translatedTitle
      })
    }
  };

  const logout = async () => {
    customPushToDataLayer({ 'event': 'logout'});
    clearAuthToken();
    window.location.reload();
  };

  const handleChangeCountry = () => {
    history.push('/')
    window.location.reload();
  }

  const termsOfUsePath = termsOfUseLink(i18n.language)
  const privacyPolicyPath = privacyPolicyLink(i18n.language)

  return (
      <div className="menu-container">
        <menu className={`menu ${settings.showMenu ? "menu--visible" : ""}`}>
          <button
              className="menu__close button"
              onClick={() => onClickOut()}
              aria-label={"close menu"}
          >
            <Icon name="close" stroke="#1E223C" fill="none" size={60} />
          </button>
          <ul className="menu__items">
            <li onClick={() => onClickOut('home', t("menu.home"))}>
              <Link to="/home">{t("menu.home")}</Link>
            </li>
            <li onClick={() => onClickOut('Virtual Roleplays', t("menu.virtual_roleplays"))}>
              <Link to="/roleplay">{t("menu.virtual_roleplays")}</Link>
            </li>
            <li onClick={() => onClickOut('About', t("menu.about"))}>
              <Link to="/about">{t("menu.about")}</Link>
            </li>
            <li onClick={() => onClickOut('References', t("menu.references"))}>
              <Link to="/references">{t("menu.references")}</Link>
            </li>
          </ul>
          <div className="menu__user">
            {username && <span style={buttonOverrideStyle}>{username}</span>}
            {settings.isAuthEnabled
                ? <button className="menu__logout button" onClick={logout}>
                  <Icon
                      name="logout"
                      stroke="#1E223C"
                      fill="none"
                      size={30}
                      height={20}
                  />
                  <span style={buttonOverrideStyle}>{t("menu.logout")}</span>
                </button>
                : <button className="menu__logout button" onClick={handleChangeCountry}>
                  <span style={buttonOverrideStyle}>Change country</span>
                </button>}
          </div>
          <ul className="menu__info">
            <li onClick={() => onClickOut('Terms Of Use', t("menu.terms_of_use"))}>
              <Link to={{ pathname: termsOfUsePath }} target="_blank">{t("menu.terms_of_use")}</Link>
            </li>
            <li onClick={() => onClickOut('Privacy Policy', t("menu.privacy_policy"))}>
              <Link to={{ pathname: privacyPolicyPath }} target="_blank">{t("menu.privacy_policy")}</Link>
            </li>
            <li onClick={() => onClickOut('Cookie Policy', t("menu.cookie_policy"))}>
              <Link to="/cookie-policy">{t("menu.cookie_policy")}</Link>
            </li>
          </ul>
        </menu>
        <div className="menu__copyright">
          <span>{"\u00A9 Sanofi "+ new Date().getFullYear() + " - " + t("menu.copyright")}</span>
        </div>
        <BackgroundGradient />
      </div>
  );
};
