import React, { PropsWithChildren } from "react";
import { CSSTransition } from "react-transition-group";
import { BackgroundGradient } from "../BackgroundGradient/BackgroundGradient";

export const StaticPage: React.FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {

  return (
    <CSSTransition
      in={true}
      classNames={"slide-in"}
      timeout={600}
      appear={true}
      mountOnEnter
      unmountOnExit
    >
      <div className="static-page">
        <div className="static-page__content">
          <div className="static-content">
            {children}
          </div>
        </div>
        <BackgroundGradient />
      </div>
    </CSSTransition>
  );
};
