import React from "react";
import { CSSTransition } from "react-transition-group";
import { Icon } from "../../Layout/Icon/Icon";

export interface ControlButtonProps {
  onButtonClick: () => void;
  buttonClassName?: string;
  ariaLabel: string;
}

export const ControlButton: React.FunctionComponent<ControlButtonProps> = ({
  onButtonClick,
  buttonClassName,
  ariaLabel,
}) => (
  <CSSTransition in classNames="fade" appear={true} timeout={800}>
    <button
      className={`button column-controls__button ${buttonClassName}`}
      onClick={onButtonClick}
      aria-label={ariaLabel}
    >
      <Icon
        name="rectangle"
        stroke="#1E223C"
        fill="none"
        size={30}
        height={55}
      />
    </button>
  </CSSTransition>
);
