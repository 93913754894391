import React from "react";
import { useTranslation } from "react-i18next";
import { useSettings } from "../../../context/Settings";
import { Icon } from "../../Layout/Icon/Icon";

export interface TopBarProps {
  isCardOpen: boolean;
  setIsCardOpen: () => void;
  openCardText: string;
  closeCardText: string;
}

export const TopBar: React.FunctionComponent<TopBarProps> = ({
  isCardOpen,
  setIsCardOpen,
  openCardText,
  closeCardText,
}) => {
  const { t } = useTranslation();
  const isSafari = useSettings().settings.isSafari;
  const buttonOverrideStyle = isSafari ? { marginTop: "5px" } : {};

  return (
    <div className="content-about">
      <button
        className={`card-button ${isCardOpen ? "card-button--close" : ""} button`}
        onClick={setIsCardOpen}
        aria-label={isCardOpen ? `${closeCardText}` : `${openCardText}`}
      >
        {isCardOpen ? (
          <Icon name="close" stroke="#F9DDD2" fill="none" size={38} />
        ) : (
          <>
            <span style={buttonOverrideStyle}>{t('roleplay.show_card')}</span>
            <Icon name="document" fill="#1E223C" size={38} className="hovered-icon"/>
          </>
        )}
      </button>
    </div>
);
}