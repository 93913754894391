import { SpeechConfig, AudioConfig, SpeechRecognizer } from "microsoft-cognitiveservices-speech-sdk";
import React, { useCallback, useContext } from "react";
import { useEffect, useState } from "react";
import { customPushToDataLayer, RoleplayEvent, shortenText } from "../../../analytics/analytics";
import { getSpeechRecognitionLangCode } from "../../../i18n/i18n";
import { getTokenOrRefresh } from "../../../utils/token_util";
import { extendedAnswerType } from "./Chat";

export const useRecognizer = (language: string) => {

    const [recognizer, setRecognizer] = useState<SpeechRecognizer | null>(null);

    const recreateRecognizer = useCallback(async () => {
      const tokenRes = await getTokenOrRefresh();
      if (tokenRes.authToken === null) {
        console.error('Unable to connect to speech recognition service ', tokenRes.error)
      }
      else {
        const speechConfig = SpeechConfig.fromAuthorizationToken(
          tokenRes.authToken,
          tokenRes.region
        );
        speechConfig.speechRecognitionLanguage = getSpeechRecognitionLangCode(language);

        const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new SpeechRecognizer(
          speechConfig,
          audioConfig
        );
        setRecognizer(recognizer);
      };
    }, [language])

    // Initialize speech recognition
    useEffect(() => {
      recreateRecognizer()
    }, [recreateRecognizer]);

    // Refresh token every <= 10 minutes (3 min cached in cookie)
    useEffect(() => {
      const interval = setInterval(async () => {
        const token = await getTokenOrRefresh();
        if (recognizer) recognizer.authorizationToken = token.authToken
      }, 400000)
      return () => clearInterval(interval);
    }, [recognizer]);

    return { recognizer, recreateRecognizer }
}


export const sendUserAnswerAnalytics = (
  currentAnswer: extendedAnswerType | null,
  nextAnswer: extendedAnswerType,
  questionID: string,
  micSelected: boolean,
  roleplayTemplateID: string,
  userAnswerText: string,
  isMicEnabled: boolean,
  language: string
) => {
  customPushToDataLayer({
    event: 'roleplay_interaction',
    roleplay_action: 'Answer',
    roleplay_name: roleplayTemplateID,
    roleplay_language: language,
    patient_answer_id: currentAnswer?.id,
    patient_answer_text: currentAnswer ? shortenText(currentAnswer.text) : undefined,
    user_answer_id: questionID,
    user_answer_text: shortenText(userAnswerText),
    patient_answer_id_response: nextAnswer.id,
    patient_answer_text_response: shortenText(nextAnswer.text),
    answer_outcome: nextAnswer.result || 'next',
    answer_method: micSelected ? 'speak' : 'click',
    microphone_allowed: isMicEnabled
  });
  if (nextAnswer.result) {
    customPushToDataLayer({
      event: 'roleplay_end',
      roleplay_name: roleplayTemplateID,
      roleplay_language: language,
      roleplay_action: 'end',
      roleplay_result: nextAnswer.result,
      patient_answer_id: nextAnswer.id,
      patient_answer_text: shortenText(nextAnswer.text)
    })
  }
}


type ChatContextType = {
  sendAnalyticsInChatContext: (type: RoleplayEvent) => void;
}

export const ChatContext = React.createContext<ChatContextType>({
  sendAnalyticsInChatContext: {} as any
});

export const useChatContext = () => {
  const context = useContext(ChatContext);
  return context;
};

export const startSpeechReco = (
    recognizer: SpeechRecognizer, 
    callbackRecognized: (recognizedText: string) => any
  ) => {
  recognizer?.startContinuousRecognitionAsync();
  recognizer.recognized = (_, { result }) => {
    callbackRecognized(result.text)
  }
}

export const stopSpeechReco = (recognizer: SpeechRecognizer | null) => {
  if (!recognizer) return
  recognizer.stopContinuousRecognitionAsync()
  recognizer.recognized = () => {}
}