import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useSettings } from '../../context/Settings';
import { StaticPage } from './StaticPage'

export type CookieData = {
  header: string;
  paragraphs: string[]
}

export const CookiePolicyPage = () => {
  const [cookieData, setCookieData] = useState<CookieData | null>(null);
  const { settings } = useSettings();
  const language = settings.language

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(`/cookie-policies/${language}.json`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (response.data) {
        setCookieData(response.data);
      }
    };
    getData();
  }, [setCookieData, language])

  //List of cookies generated by one trust script
  const otCookiesListRef = useRef<HTMLElement | null>(null);
  const componentCookiesListRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const cookiesListHtml = document.getElementById('ot-sdk-cookie-policy');
    otCookiesListRef.current = cookiesListHtml;
  }, [])

  // Move cookies list generated by external one trust script into this component
  useEffect(() => {
    if (!otCookiesListRef.current || !componentCookiesListRef.current) return;

    const componentsAlreadyHasCookiesList = componentCookiesListRef.current?.children.length > 0;
    if (componentsAlreadyHasCookiesList) return;

    componentCookiesListRef.current.appendChild(otCookiesListRef.current)
  })

  // Remove cookies list generated by external one trust script form this component
  useEffect(() => {
    return () => {
      if (!otCookiesListRef.current) return;
      const cookiesListCreatedByOneTrust = otCookiesListRef.current;
      document.getElementById('one-trust-container')?.appendChild(cookiesListCreatedByOneTrust);
    }
  }, [])

  if (!cookieData) return <></>;

  const click = () => {
    const realButton = document.getElementById('ot-sdk-btn');
    realButton?.click();
  }

  const buttonStyleOverride = settings.isSafari ? { paddingTop: "20px"} : {};

  return (
    <StaticPage>
      <h1>{cookieData.header}</h1>
      {cookieData.paragraphs.map((text) => (<p>{text}</p>))}
      <button 
        onClick={click} 
        className={'cookie-settings-btn button'}
        style={buttonStyleOverride}
      >
        Cookie Settings
      </button>
      <div ref={componentCookiesListRef}></div>
    </StaticPage>
  )
}
