import React, { useCallback, useRef } from "react";
import { VideoPlayer } from "../../VideoPlayer/VideoPlayer";

export interface VideosSwitchProps {
  videoId: string | null;
  videoUrl: string | null;
  videoPlayEnded: () => void;
  setShowAnswerVideo: React.Dispatch<boolean>;
  waitingVideoId: string | null;
  waitingVideoUrl: string | null;
  showAnswerVideo: boolean
}

export const VideosSwitch: React.FunctionComponent<VideosSwitchProps> = React.memo(({
  videoId,
  videoUrl,
  videoPlayEnded,
  setShowAnswerVideo,
  waitingVideoId,
  waitingVideoUrl,
  showAnswerVideo
}) => {

  const waitingVideoDiv = useRef<HTMLDivElement | null>(null);

  const onCanPlay = useCallback(
    () => {
      setShowAnswerVideo(true)
      waitingVideoDiv.current?.querySelector('video')?.pause() //solves problem with looped brightcove videos on ios
  },
    [setShowAnswerVideo],
  )

  const onFinished = useCallback(
    () => {
      const videoElement = waitingVideoDiv.current?.querySelector('video');
      if (videoElement) {
        videoElement.currentTime = 0
        videoElement.play();
      }
      videoPlayEnded()
    },
    [videoPlayEnded],
  )

  return (
    <div className="roleplay__person default-video default-video--visible">
      <div className="waiting-video"
        ref={waitingVideoDiv}
      >
        {waitingVideoId && waitingVideoUrl && (
          <VideoPlayer
            accountId={"3845398857001"}
            videoId={waitingVideoId}
            videoUrl={waitingVideoUrl}
            loop={true}
          />
        )}
      </div>
    <div
      className={`answer-video ${
        showAnswerVideo ? "answer-video--visible" : ""
      }`}
      id="answer-video-div"
    >
      {videoId && videoUrl && (
        <VideoPlayer
          accountId={"3845398857001"}
          videoId={videoId}
          videoUrl={videoUrl}
          onEnded={onFinished}
          onCanPlay={onCanPlay}
          unmuted={true}
        />
      )}
    </div>
  </div>
);
})