import React, { FC } from 'react';
import SanofiLogo from '../SanofiLogo/SanofiLogo';
import { ReactComponent as ReactLogo } from '../../assets/dialog-logo.svg';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const isInIframe = window !== window.parent;

interface NotSupportedBrowserProps {}

export const NotSupportedBrowser: FC<NotSupportedBrowserProps> = ({ children }) => {
  return (
    <>
      {isSafari && isInIframe ? (
        <div className="animation-overlay">
          <SanofiLogo />

          <div className="login-center">
            <ReactLogo className="login-center__logo" width="" height="" />
            <h3 style={{ fontSize: '30px', lineHeight: '44px' }}>Browser not supported.</h3>

            <p className="login-center__text" style={{ margin: 'auto' }}>
              Please go to{' '}
              <a
                href="https://www.dialog.coach/"
                target="_blank"
                rel="noopener noreferrer"
                className="description-text description-text--bold"
              >
                www.dialog.coach{' '}
              </a>
              directly.
            </p>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};
