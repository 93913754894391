import React from "react";
import { answerType, questionType } from "../types";

export type HistoryEntry = answerType & { clickedQuestionID: string };
export interface HistoryProps {
  chatHistory: HistoryEntry[];
  activeRoleplayQuestions: Record<string, questionType>;
}

export const History: React.FunctionComponent<HistoryProps> = React.memo(({
  chatHistory,
  activeRoleplayQuestions,
}) => (
  <>
    {chatHistory.map(({ text, questions: questionIDs, clickedQuestionID: selectedAnswerID }, i) => (
      <div className="dialog-box" key={i}>
        <div className="balloons-wrapper">
          <p className="dialog-balloon dialog-balloon--answer">{text}</p>
        </div>
        <div className="balloons-wrapper balloons-wrapper--questions-history">
          <ol>
            {questionIDs?.map((questionID) => (
              <li
                key={questionID}
                className={
                  !activeRoleplayQuestions[questionID].clicked &&
                    selectedAnswerID === questionID
                    ? ""
                    : "question-disabled"
                }
              >
                <button
                  className="button dialog-balloon"
                  disabled={activeRoleplayQuestions[questionID].disabled}
                  tabIndex={-1}
                >
                  {activeRoleplayQuestions[questionID].text}
                </button>
                {activeRoleplayQuestions[questionID].additionalInfo && (
                  <div className="question-info">
                    <small>
                      {activeRoleplayQuestions[questionID].additionalInfo}
                    </small>
                  </div>
                )}
              </li>
            ))}
          </ol>
        </div>
      </div>
    ))}
  </>
));
