import React from "react";
import { Icon } from "../../Layout/Icon/Icon";

export interface MicrophoneButtonProps {
  isMicEnabled: boolean;
  isMicListening: boolean;
  toggleMic: () => void;
}

export const MicrophoneButton: React.FunctionComponent<MicrophoneButtonProps> =
  ({ isMicEnabled, toggleMic, isMicListening }) => (
    <div className="microphone-button__wrapper">
      <button
        className={
          `button content-dialog__button 
          ${isMicEnabled ? "content-dialog__button--enabled" : ""}
          ${isMicListening ? "pulse" : ""}`
        }
        aria-label="Allow microphone"
        onClick={toggleMic}
      >
        <Icon
          name="mic"
          stroke="#F9DDD2"
          fill="#F9DDD2"
          size={21}
          height={40}
        />
      </button>
    </div>
  );
