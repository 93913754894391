import axios, { AxiosResponse } from "axios";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useSettings } from "../../context/Settings";
import { LoginResponse, setAuthToken } from "../../utils/authentication";
import { ReactComponent as ReactLogo } from '../../assets/dialog-logo.svg';
import { forgottenPasswordLink } from "./login.helpers";
import SanofiLogo from "../SanofiLogo/SanofiLogo";
import { hcpPortalLink } from "../Navbar/components/menu.helpers";
import { customPushToDataLayer } from "../../analytics/analytics";
import { getCountryName } from "../../i18n/i18n";

export const LoginPage = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const { settings, saveSettings } = useSettings();
  const [invalid, setInvalid] = useState(false);
  const location = useLocation();
  const language = location.pathname.split('/').pop();
  const history = useHistory();

  if (!language) return <></>;

  const loginSuccessEvent = (userID: string) => customPushToDataLayer({
    event: 'login',
    user_id: userID,
    country: getCountryName(language)
  })
  
  const loginFailEvent = () => customPushToDataLayer({
    event: 'login_error',
    error: 'Incorrect email or password',
    country: getCountryName(language)
  })

  const submit = (e: React.MouseEvent) => {
    e.preventDefault();
    axios.post('/api/login', {
      userName: login,
      password: password,
      countryCode: language
    }).then((response: AxiosResponse<LoginResponse>) => {
      if (response.status === 200) {
        const { fullName, token, countryCode, id } = response.data;
        saveSettings({
          userName: fullName,
          isAuthorized: true,
          language: countryCode as any
        })
        setAuthToken(token);
        customPushToDataLayer({ user_id: id, country: getCountryName(countryCode) })
        loginSuccessEvent(id);

        return history.push('/home')
      }
    }).catch((error) => {
      if (error.response.status === 401) {
        setInvalid(true);
        loginFailEvent();
      }
    })
  }

  const goBack = () => {
    history.push('/login')
  }

  const buttonStyleOverride = settings.isSafari ? { paddingTop: "20px"} : {};
  const inputStyleOverride = settings.isSafari ? { paddingTop: "4px"} : {};

  const changeValue = (e: React.ChangeEvent<HTMLInputElement>, login: boolean) => {
    if (login) setLogin(e.target.value)
    else setPassword(e.target.value);
    setInvalid(false)
  }

  const changeLogin = (e: React.ChangeEvent<HTMLInputElement>) => changeValue(e, true)
  const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => changeValue(e, false)
  const forgottenPassLink = forgottenPasswordLink(language);
  const hcplink = hcpPortalLink(language);

  return (
    <div className="animation-overlay">
      <SanofiLogo/>
      <div className="login-center">
        <ReactLogo className="login-center__logo" width="" height=""/>
        <p className="login-center__text">
          <span>Use your Sanofi HCP portal login details to access the site.<br/><strong>If you are not yet registered</strong> on the portal, you can do so </span>
          <a href={hcplink} rel="noopener noreferrer" target="_blank" className='login-center__link'><span>here</span></a>. 
        </p>
        <form className={`login-form ${invalid ? 'login-form--invalid': ''}`}>
          <input placeholder={'Your email'} onChange={changeLogin} style={inputStyleOverride}/>
          <input placeholder={'Password'} onChange={changePassword} type='password' style={inputStyleOverride}/>
          {invalid && <p className='login-form__error'>Incorrect email or password</p>}
          <div className='login-form__buttons'>
            <button 
              id="login-button"
              className="login-form-button button" 
              onClick={submit}
              style={buttonStyleOverride}
              >
              login
            </button>
            <button 
              className="login-form-button login-form-button--back button" 
              onClick={goBack}
              style={buttonStyleOverride}
              >
              back
            </button>
          </div>
          <a href={forgottenPassLink} className="login-form__subtext" rel="noopener noreferrer" target="_blank">Forgot your password?</a>
        </form>
      </div>
    </div>
  )
};
