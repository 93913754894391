import React from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { Sanitize } from "../../Sanitize/Sanitize";
import { roleplayDataTypes } from "../types";

export interface CardProps {
  isCardOpen: boolean;
  roleplayData: roleplayDataTypes;
}

export const Card: React.FunctionComponent<CardProps> = ({
  isCardOpen,
  roleplayData
}) => {
  const { name, age, hba, bmi, medicalHistory } = roleplayData;
  const { t } = useTranslation();

  return (
    <CSSTransition in={isCardOpen} classNames="fade" appear={true} timeout={800}>
      <div className="card-history">
        <h1 className="card-history__header">{name}</h1>
        <div className="card-history__info-wrapper">
          <p>
            <span>{t("roleplay.card_age")}:</span> {age}
          </p>
          <p>
            <span>{t("roleplay.card_current")} HbA1c:</span> {hba}
          </p>
          <p>
            <span>BMI:</span> {bmi}
          </p>
        </div>
        <Sanitize html={medicalHistory} />
      </div>
    </CSSTransition>
  );
}
