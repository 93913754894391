import { extendedAnswerType } from '../components/Roleplay/components/Chat';
import {
  DataLayerObject,
} from './types';

type DataLayer = { dataLayer: Object[]};

const pushToDataLayer = (obj: DataLayerObject) => {
  const w = window as typeof window & DataLayer;
  w.dataLayer = w.dataLayer || [];
  w.dataLayer.push(obj);
};

export const customPushToDataLayer = (obj: object) => {
  const w = window as typeof window & DataLayer;
  w.dataLayer = w.dataLayer || [];
  w.dataLayer.push(obj);
};

const setUserData = (userId: string) => {
  pushToDataLayer({
    event: 'customUser',
    userData: {
      userId,
    },
  });
};

const createPageEvent = (path: string) => {
  const obj: DataLayerObject = {
    event: 'customPage',
    pageData: {
      path,
    },
  };
  pushToDataLayer(obj);
};

export const createGenericEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number,
  nonInteraction?: boolean,
) => {
  const obj: DataLayerObject = {
    event: 'customEvent',
    eventData: {
      category,
      action,
      label,
      value,
      nonInteraction,
    },
  };
  pushToDataLayer(obj);
};

export default {
  createPageEvent,
  createGenericEvent,
  setUserData,
};

export const shortenText = (text: string) => {
  const length = 50;
  if (text.length > length) return text.substr(0, length) + '...';
  else return text;
}

export enum RoleplayEvent {
  replay = 'Re-play answer',
  patient_info = 'View Patient Information',
  allow_mic = 'Allow microhone',
  enable_mic = 'Activate microphone',
  disable_mic = 'De-activate microphone',
  skip_answer = 'Skip patient spoken answer',
  leave = 'Leave roleplay',
  stay = 'Stay in roleplay',
  finish = 'Finish roleplay',
  try_again = 'Try again',
  restart = 'Restart roleplay',
  quick_tip = 'Quick tip'
}

export const analyticsRPEvent = (
  type: RoleplayEvent,
  roleplayName: string,
  rolaplayLanguage: string,
  micAllowed: boolean,
  patientAnswerID?: string,
  patientAnswerText?: string,
) => {
  const answer = {
    event: 'roleplay_interaction',
    roleplay_action: type,
    roleplay_name: roleplayName,
    roleplay_language: rolaplayLanguage,
    microphone_allowed: micAllowed
  }

  const patientAnswerInfo = (patientAnswerID && patientAnswerText) 
  ? {
    patient_answer_id: patientAnswerID,
    patient_answer_text: shortenText(patientAnswerText),
  }
  : {}

  customPushToDataLayer({...answer, ...patientAnswerInfo})
}

export type AnalyticsRoleplayPart = {
  roleplayName: string;
  rolaplayLanguage: string;
  micAllowed: boolean;
}

export type AnalyticsCurrentAnswerPart = {
  patientAnswerID?: string;
  patientAnswerText?: string;
}

export let currentAnalyticsRoleplayContext: AnalyticsRoleplayPart | null = null;
export let currentAnalyticsChatContext: AnalyticsCurrentAnswerPart = {}
export const setRoleplayContextForAnalytics = (data: AnalyticsRoleplayPart) => {
  currentAnalyticsRoleplayContext = data
}
export const setChatContextForAnalytics = (data: AnalyticsCurrentAnswerPart) => {
  currentAnalyticsChatContext = data
}

export const analyticsEventInRoleplayContext = (
  type: RoleplayEvent,
  patientAnswerID?: string,
  patientAnswerText?: string
) => {
  if (!currentAnalyticsRoleplayContext) {
    console.warn('Analytics error');
    return;
  }
  analyticsRPEvent(
    type,
    currentAnalyticsRoleplayContext.roleplayName,
    currentAnalyticsRoleplayContext.rolaplayLanguage,
    currentAnalyticsRoleplayContext.micAllowed,
    patientAnswerID,
    patientAnswerText
  );
};

export const analyticsEventInChatContext = (type: RoleplayEvent) => {
  analyticsEventInRoleplayContext(
    type,
    currentAnalyticsChatContext.patientAnswerID,
    currentAnalyticsChatContext.patientAnswerText
  )
}

export const sendUserAnswerAnalytics = (
  patientAnswer: extendedAnswerType | null,
  patientAnswerOutcome: extendedAnswerType,
  questionID: string,
  micSelected: boolean,
  roleplayTemplateID: string,
  userAnswerText: string,
  isMicEnabled: boolean,
  language: string
) => {
  customPushToDataLayer({
    event: 'roleplay_interaction',
    roleplay_action: 'Answer',
    roleplay_name: roleplayTemplateID,
    roleplay_language: language,
    patient_answer_id: patientAnswer?.id,
    patient_answer_text: patientAnswer ? shortenText(patientAnswer.text) : undefined,
    user_answer_id: questionID,
    user_answer_text: shortenText(userAnswerText),
    patient_answer_id_response: patientAnswerOutcome.id,
    patient_answer_text_response: shortenText(patientAnswerOutcome.text),
    answer_outcome: patientAnswerOutcome.result || 'next',
    answer_method: micSelected ? 'speak' : 'click',
    microphone_allowed: isMicEnabled
  });
  if (patientAnswerOutcome.result) {
    customPushToDataLayer({
      event: 'roleplay_end',
      roleplay_name: roleplayTemplateID,
      roleplay_language: language,
      roleplay_action: 'end',
      roleplay_result: patientAnswerOutcome.result,
      patient_answer_id: patientAnswerOutcome.id,
      patient_answer_text: shortenText(patientAnswerOutcome.text)
    })
  }
}

export const userAnswerAnalytics = (
  currentAnswer: extendedAnswerType | null,
  nextAnswer: extendedAnswerType,
  questionID: string,
  micSelected: boolean,
  questionText: string
) => {
  if (!currentAnalyticsRoleplayContext) {
    console.warn('Analytics error')
    return
  }
  sendUserAnswerAnalytics(
    currentAnswer,
    nextAnswer,
    questionID,
    micSelected,
    currentAnalyticsRoleplayContext.roleplayName,
    questionText,
    currentAnalyticsRoleplayContext.micAllowed,
    currentAnalyticsRoleplayContext.rolaplayLanguage
  )
}