import axios, { AxiosResponse } from 'axios';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';
import { customPushToDataLayer } from '../../analytics/analytics';
import { useSettings } from '../../context/Settings';
import { getCountryName } from '../../i18n/i18n';
import { clearAuthToken, getAuthenticationToken } from '../../utils/authentication';

type AuthResponse = {
  userFullName: string;
  userCountryCode: string;
  userId: string;
}

export const Authentication: React.FunctionComponent<{path: string | undefined}> = ({ path }) => {
  const { saveSettings } = useSettings();
  const history = useHistory();
  const { i18n } = useTranslation();

  useEffect(() => {
    const authToken = getAuthenticationToken();
    if (!authToken) return history.push('/login')
    const authenticate = () => {
      axios
        .get("api/auth", {
          headers: { 'Authorization': `Bearer ${authToken}`},
        })
        .then((response: AxiosResponse<AuthResponse>) => {
          const { userFullName, userCountryCode, userId } = response.data;
          saveSettings({ userName: userFullName, isAuthorized: true, language: userCountryCode as any });
          customPushToDataLayer({ user_id: userId, country: getCountryName(userCountryCode) })

          if (path) history.push(path)
          else history.push('/home')
        })
        .catch(function (error) {
          clearAuthToken();
          if (error.response.status === 401) return history.push('/login')
        });
    };
    authenticate();
  }, [saveSettings, history, i18n, path]);
  
  return <></>;
};
